.choose-wrap {
    background-color: $light-blue;
    padding: 50px 25px 35px 25px;
    margin-bottom: 75px;

    @include mobile {
        padding: 30px 15px 15px 15px;
        margin-bottom: 30px;
    }

    .container {
        margin-bottom: 0;
    }

    .row {
        &:first-child {
           margin-bottom: 20px;
        }
    }

    img {
        width: 65px;
        margin: auto;
        @include mobile {
            width: 100px;

        }
    }

    .col-md-6 {
        display: flex;

        @include mobile {
            display: block
        }
    }

    .img-wrap {
        text-align: center;
    }

    .info-wrap {
        @include desktop {

            padding-left: 20px
        }
    }
}