.modal-dialog {
    top: 20%;
    max-width: 550px;

    .btn-primary {
        min-width: 150px;
        background-color: $green;
        border: none;

        &:hover {
            background-color: #62e135;
        }
    }

    h3 {
        color: $gray;
        margin-bottom: 20px;
    }
}

.modal {
    &.show {
        background: #0000009c;
        display: block;
    }
}

.modal-header {
    text-align: center;
    border-bottom: none;
    position: relative;
    justify-content: center;
    margin-top: 50px;


}

.modal-content {
    border-radius: 20px;
    padding: 0 20px;

    &::before {
        content: "";
        background-image: url(../../images/icons/social-media/check.svg);
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: $green;
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
    }

    .btn-secondary {
        background-color: $blue;
        border: none;
        min-width: 100px;
    }
}

.modal-body {
    text-align: center;
}

.modal-footer {
    border-top: none;
    justify-content: center;
}