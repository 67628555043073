.send-message {
    margin-bottom: 40px

}

.map {

    iframe {
        width: 100%;
        max-height: 300px;
        border: 1px solid;
    }


}