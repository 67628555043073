.topNav {
    position: fixed;
    top: 0;
    background-color: $blue;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 16;

    .container {
        margin-bottom: 0;
        padding-top: 5px;
        padding-bottom: 5px;

    }

    ul {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        justify-content: flex-end;
        li {
            margin-right: 20px;
         
            &:last-child {
                margin-right: 0
            }
        }

        img {
            max-width: 15px;
            margin-right: 10px;
        }

        a {
            color: $white;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            white-space: nowrap
      
        }
    }

    .socilaMedia {
        li {
            margin-right: 5px;
            &:first-child {
                margin-right: 15px;
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .right {
        @include mobile {
            margin-right: 20px;
            padding-left: 0;
        }
    }
}